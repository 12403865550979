import DefaultProfileImage from "../../Utills/DefaultProfile";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PlusIcon } from "../../images/follow-plus-icon.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-s-line.svg";
import {
  createFollowerApi,
  genarateDeepLink,
  getFollowerCheckApi,
  updateFollowerApi,
} from "../../redux/slices/app/appService";
import { useEffect, useState } from "react";
import { ProductDetailsStyled } from "./ProductDeatilsStyled.styled";
import LoginModal from "../TopHeader/LoginModal";
import { ReactComponent as FaceBook } from "../../images/facebook-circle-fill.svg";
import { ReactComponent as Youtube } from "../../images/youtube-fill.svg";
import { ReactComponent as Instagram } from "../../images/instagram-fill.svg";
import { ReactComponent as TickTok } from "../../images/tiktok-fill.svg";
import { ReactComponent as SnapChat } from "../../images/snapchat-fill.svg";
import { ReactComponent as X } from "../../images/twitter-x-fill.svg";
import { getBrandLogoUrl } from "../../Utills/utils";
import { getUrlSlug } from "../../library/Requests/helpers";

const ProductDetails = (props: any) => {
  const {
    auth: { user },
  } = useSelector((state: any) => state);
  const { videoDetails, productDetails } = props;
  const [isFollowed, setIsFollowed] = useState(false);
  const [follwedId, setFollowedId] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [redirectionUrl, setRedirectionUrl] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [deepLink, setDeepLink] = useState("");

  const handleFollowUser = async () => {
    setIsLoading(true);
    if (user?._id) {
      if (!isFollowed) {
        let json = {
          userId: user?._id,
          followerId: videoDetails?.CreatorDetails?._id,
        };
        await dispatch(createFollowerApi(json));
        await handleUserFollowedChecked();
      } else {
        await dispatch(updateFollowerApi(follwedId));
        await handleUserFollowedChecked();
      }
    } else {
      history.push("/login");
    }
    setIsLoading(false);
  };

  const handleUserFollowedChecked = async () => {
    let json = {
      userId: user?._id,
      followerId: videoDetails?.CreatorDetails?._id,
    };
    let res: any = await dispatch(getFollowerCheckApi(json));

    if (res.payload) {
      setIsFollowed(true);
      setFollowedId(res?.payload?.data?._id);
    } else {
      setIsFollowed(false);
      setFollowedId("");
    }
  };

  useEffect(() => {
    if (user?._id && videoDetails?.CreatorDetails?._id) {
      handleUserFollowedChecked();
    }
  }, [user, videoDetails?.CreatorDetails?._id]);

  const handleRedirect = (url: any) => {
    if (user?.id) {
      window.open(url);
    } else {
      setUrlPath("/login");
      setLoginModal(true);
      setRedirectionUrl(`${url}`);
    }
  };

  useEffect(() => {
    handleGenrateDeepLink(videoDetails?.brand?.merchantUrl);
  }, [videoDetails]);

  const handleGenrateDeepLink = async (url: any) => {
    let json = {
      merchantUrl: url,
      placement_id: "",
      click_id: "",
      campaign_id: 5029,
      isRedClubAI: true,
      new_campaign_id: getUrlSlug(videoDetails?.CreatorDetails?.Name),
      apiKey: "6wJK70SBvx04RQOiHG5mKwJ6Tyz9D2fxulct6jIaWcQCUgIYnI",
      appName: "onlyDrops",
    };
    let res: any = await dispatch(genarateDeepLink(json));

    setDeepLink(res.payload?.deepLinkInfo?.link);
  };

  const handleSliptUserName = (name: any) => {
    if (name) {
      let userName = name?.split("/");
      let socailUserName = userName[userName.length - 1];
      console.log(socailUserName);
      return socailUserName;
    }
  };

  return (
    <>
      <LoginModal
        show={loginModal}
        onHide={() => {
          setLoginModal(false);
        }}
        urlPath={urlPath}
        setUrlPath={setUrlPath}
        isDealDisplay={false}
        redirectionUrl={redirectionUrl}
      />
      <div className="col-6">
        <header className="product-header">
          <h2>{videoDetails?.VideoTitle}</h2>
        </header>
        <section>
          {/* <div className="posted-time">
          {getRelativeTime(videoDetails?.CreatedAt)}
        </div> */}
          <div
            className="profile-details-sec"
            onClick={() => {
              if (user?._id) {
                history.push(`/profile/${videoDetails?.CreatorDetails?._id}`);
              }
            }}
          >
            {videoDetails?.CreatorDetails?.imageUrl ? (
              <img src={videoDetails?.CreatorDetails?.imageUrl} alt="profile" />
            ) : (
              <DefaultProfileImage
                userName={videoDetails?.CreatorDetails?.userName}
                fullName={videoDetails?.CreatorDetails?.Name}
                email={videoDetails?.CreatorDetails?.Email}
              />
            )}

            <p className="proflie-name">{videoDetails?.CreatorDetails?.Name}</p>
            <button
              onClick={(e: any) => {
                e.stopPropagation();
                !loading && handleFollowUser();
              }}
            >
              {!follwedId ? (
                <>
                  Follow
                  <PlusIcon />
                </>
              ) : (
                "Un Follow"
              )}
            </button>
            <div className="socail-login-profile">
              {(videoDetails.CreatorDetails?.socialAccounts?.facebook ||
                videoDetails.CreatorDetails?.socialAccounts?.tiktok ||
                videoDetails.CreatorDetails?.socialAccounts?.instagram ||
                videoDetails.CreatorDetails?.socialAccounts?.youtube ||
                videoDetails.CreatorDetails?.socialAccounts?.snapchat ||
                videoDetails.CreatorDetails?.socialAccounts?.x) && (
                <p>Connect With {videoDetails?.CreatorDetails?.Name} on</p>
              )}
              <div>
                {videoDetails.CreatorDetails?.socialAccounts?.facebook && (
                  <div>
                    <FaceBook
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts
                              ?.facebook
                          )}`
                        );
                      }}
                    />
                  </div>
                )}

                {videoDetails.CreatorDetails?.socialAccounts?.tiktok && (
                  <div>
                    <TickTok
                      onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(
                          `https://www.tiktok.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts?.tiktok
                          )}`
                        );
                      }}
                    />
                  </div>
                )}
                {videoDetails.CreatorDetails?.socialAccounts?.instagram && (
                  <div>
                    <Instagram
                      onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(
                          `https://www.instagram.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts
                              ?.instagram
                          )}`
                        );
                      }}
                    />
                  </div>
                )}
                {videoDetails.CreatorDetails?.socialAccounts?.youtube && (
                  <div>
                    <Youtube
                      onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(
                          `https://www.youtube.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts?.youtube
                          )}`
                        );
                      }}
                    />
                  </div>
                )}
                {videoDetails.CreatorDetails?.socialAccounts?.snapchat && (
                  <div>
                    <SnapChat
                      onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(
                          `https://www.snapchat.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts
                              ?.snapchat
                          )}`
                        );
                      }}
                    />
                  </div>
                )}
                {videoDetails.CreatorDetails?.socialAccounts?.x && (
                  <div>
                    <X
                      onClick={(e: any) => {
                        e.stopPropagation();
                        window.open(
                          `https://x.com/${handleSliptUserName(
                            videoDetails.CreatorDetails?.socialAccounts?.x
                          )}`
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* {videoDetails?.OfferDeatils && (
        <section className="promational-sec">
          <img src={BackGroundImage} alt="background" />

          <div>
            <p>
              Spend $200+ via my link for a FREE Disneyland Pass! Enter your
              email to claim! ✨
            </p>
            <div>
              <input type="text" placeholder="Enter your email" />
              <button>Submit</button>
            </div>
          </div>
        </section>
      )} */}
        {/* <div className="product-count">{productDetails?.length} Products</div>
      <section className="product-card-sec">
        {productDetails?.map((item: any, index: number) => (
          <div key={index}>
            <ProductCard item={item} videoDetails={videoDetails} />
          </div>
        ))}
      </section> */}
        <ProductDetailsStyled>
          <section className="mt-4">
            <div className="brand-logo-section">
              <img
                src={getBrandLogoUrl(
                  videoDetails?.brand?.id,
                  videoDetails?.brand?.logo
                )}
                alt="brand"
              />
              <span
                className="shop-now-button"
                onClick={() => {
                  window.open(deepLink);
                }}
              >
                Shop Now
                <ArrowRight />
              </span>
            </div>
          </section>
          <section className="mt-4">
            <h3 className="banner-heading">Offers</h3>
            <div className="offers-section">
              {videoDetails?.banners
                ?.filter(
                  (banner: any) =>
                    banner?.title?.trim() !== "" &&
                    banner?.description?.trim() !== ""
                )
                .map((banner: any, index: number) => (
                  <div key={index} className="offer-card">
                    {/* <img src={banner.brand.logo} alt="offer" /> */}
                    <div className="offer-details-section">
                      <div className="offer-details">
                        <h4>{banner.title}</h4>
                        <p>{banner.description}</p>
                      </div>
                      <div
                        className="shop-now-button"
                        onClick={() => {
                          window.open(deepLink);
                        }}
                      >
                        Shop now
                        <ArrowRight />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </section>
          {productDetails?.length > 0 && (
            <div
              className="browse-products-button"
              onClick={() => {
                history.push(`/products/${videoDetails?.brand.id}`);
              }}
            >
              Browse Products
            </div>
          )}
        </ProductDetailsStyled>
      </div>
    </>
  );
};

export default ProductDetails;
