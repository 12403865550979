import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-s-line.svg";
import { ProductDetailsStyled } from "./ProductDeatilsStyled.styled";
import { useEffect, useState } from "react";
import { genarateDeepLink } from "../../redux/slices/app/appService";
import { getBrandLogoUrl } from "../../Utills/utils";
import { getUrlSlug } from "../../library/Requests/helpers";

const MobileOffers = () => {
  const { videoDetails } = useSelector((state: any) => state.app);

  const [deepLink, setDeepLink] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    handleGenrateDeepLink(videoDetails?.brand?.merchantUrl);
  }, [videoDetails]);

  const handleGenrateDeepLink = async (url: any) => {
    let json = {
      merchantUrl: url,
      placement_id: "",
      click_id: "",
      campaign_id: 5029,
      isRedClubAI: true,
      new_campaign_id: getUrlSlug(videoDetails?.CreatorDetails?.Name),
      apiKey: "6wJK70SBvx04RQOiHG5mKwJ6Tyz9D2fxulct6jIaWcQCUgIYnI",
      appName: "onlyDrops",
    };
    let res: any = await dispatch(genarateDeepLink(json));

    setDeepLink(res.payload?.deepLinkInfo?.link);
  };
  return (
    <ProductDetailsStyled>
      <section className="mt-4">
        {/* <h3 className="brand-heading">Brands</h3> */}
        <div className="brand-logo-section">
          <img
            src={getBrandLogoUrl(
              videoDetails?.brand?.id,
              videoDetails?.brand?.logo
            )}
            alt="brand"
          />
          <span
            className="shop-now-button"
            onClick={() => window.open(deepLink)}
          >
            Shop Now
            <ArrowRight />
          </span>
        </div>
      </section>
      <section className="mt-4">
        <h3 className="banner-heading">Offers</h3>
        <div className="offers-section">
          {videoDetails?.banners
            ?.filter(
              (banner: any) =>
                banner?.title?.trim() !== "" &&
                banner?.description?.trim() !== ""
            )
            .map((banner: any, index: number) => (
              <div key={index} className="offer-card">
                {/* <img src={banner?.brand?.logo} alt="offer" /> */}
                <div className="offer-details-section">
                  <div className="offer-details">
                    <h4>{banner?.title}</h4>
                    <p>{banner?.description}</p>
                  </div>
                  <div
                    className="shop-now-button"
                    onClick={() => window.open(deepLink)}
                  >
                    Shop now
                    <ArrowRight />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </ProductDetailsStyled>
  );
};

export default MobileOffers;
